<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <!-- QUERY DATE-RANGE -->
      <v-col cols="12" lg="5">
        <v-row>
          <v-col>
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
        </v-row>
      </v-col>

      <!-- SEARCH -->
      <v-col cols="12" sm="6" lg="4">
        <v-card class="my-0" style="height: 100%;">
          <v-card-text>
            <v-container>
              <v-row align="end">
                <v-col cols="12" class="pa-0">
                  <v-radio-group row v-model="form.search.searchBy">
                    <template v-slot:label>
                      <div>{{ $t('views.surcharges.searchBy') }}<br></div>
                    </template>
                    <v-radio :label="$t('views.surcharges.ddpgOrderId')" value="paymentOrderId"></v-radio>
                    <v-radio :label="$t('views.surcharges.merchantId')" value="merchantId"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-form @submit.native.prevent @submit="search">
                    <v-text-field outlined prepend-icon="mdi-magnify"
                      v-model="form.search.keyword"
                      placeholder="(Press ENTER to search)" clearable>
                    </v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>  
          </v-card-text>
        </v-card>
          
      </v-col>

      <v-col cols="12" sm="6" lg="2" style="height: 100%;" class="d-flex align-start flex-column">
        <v-btn block large color="grey darken-3" @click="forceRefresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>

      <!-- LIST OF ORDERS -->
      <v-col cols="12">

        <v-card class="mt-0">
          <v-card-text>

            <SSRTable ref="list-surcharges"
              :data-url="loadUrl" :data-key="'surcharges'" :schema="schema">

              <div slot="surchargeStatus" slot-scope="{item}">
                <span v-if="item.surchargeStatus === 'created'">己建立</span>
                <span v-else-if="item.surchargeStatus === 'settled'">已結算</span>
                <span v-else-if="item.surchargeStatus === 'cancelled'" style="color:red">已取消</span>
                <span v-else>{{ item.surchargeStatus }}</span>
              </div>

              <div slot="amount" slot-scope="{item}">
                {{ item.currency || 'TWD'}}<br>
                $ {{ Number(item.amount).toLocaleString() }}
              </div>

              <div slot="createdAt" slot-scope="{item}">
                {{ tsToDateString(item.createdAt) }}
              </div>
              <div slot="actions" slot-scope="{item}">
                <v-btn fab x-small color="primary" class="ml-1" @click="showInfoDialog(item)">
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
                <v-btn fab x-small color="error" class="ml-1" @click="removeSurcharge(item.surchargeId)">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <!-- Dialog for showing detail -->
    <SurchargeDetailDialog :show.sync="dialog.info.show"
      :surcharge-id="dialog.info.surchargeId">
    </SurchargeDetailDialog>
  </v-container>
</template>

<script>
  import SSRTable from '@/components/SSRTable';
  import DateRangePicker from '@/components/DateRangePicker';
  import { GetIconMixin } from '@/mixins/get-icons';

  import SurchargeDetailDialog from '@/views/dashboard/subviews/SurchargeDetailDialog';

  import axios from 'axios';
  import moment from 'moment';

  export default {
    components: {
      SSRTable,
      DateRangePicker,
      // ScopeSelector,
      SurchargeDetailDialog
    },
    mixins: [
      GetIconMixin
    ],

    mounted() {

    },

    methods: {
      onDateRangeUpdated(dates) {
        this.dates = dates;
      },
      search() {
        if (this.form.search.keyword && this.form.search.keyword.length) {
          this.form.search.queryString = `&${this.form.search.searchBy}=${this.form.search.keyword}`;
        } else {
          this.form.search.queryString = '';
        }
        //NOTE: search keyword turns null when 'cleared' by user??
      },
      forceRefresh() {
        this.$refs['list-surcharges'].loadData();
      },

      showInfoDialog(surcharge) {
        this.dialog.info.surchargeId = surcharge['surchargeId'];
        this.dialog.info.show = true;
      },

      tsToDateString(ts) {
        if (ts) {
          return moment.unix(ts).format('YYYY/MM/DD HH:mm:ss');
        }
        return '';
      },

      removeSurcharge(id) {
        this.$swal({
          text: '是否確認要刪除此負項訂單？',
          icon: 'warning',
          confirmButtonText: 'Yes!',
          showCloseButton: true
        })
        .then(result => {
          if (!result.isConfirmed) return;
          const data = {
            userId: this.user.id
          };
          console.log('data:', data, this.user);

          axios.delete(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-surcharges/${id}`, data)
          .then(response => {
            this.$notify({
              type: 'success', text: '已經取消此負項訂單。'
            });
            this.forceRefresh();
          })
          .catch(err => {
            let errMsg = '發生錯誤，無法取消此負項訂單。';
            if (err.response && err.response.data && err.response.data.message) {
              errMsg = err.response.data.message;
            } 
            this.$notify({
              type: 'error', text: errMsg
            });
          })
          .finally(() => {
          });
        });
      }
    },

    data() {
      return {
        dates: [
          moment().startOf('day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        scope: {
          // organization: null,
          // merchant: null
        },

        form: {
          search: {
            searchBy: 'paymentOrderId',
            keyword: '',
            queryString: ''
          }
        },
        dialog: {
          info: {
            show: false,
            surchargeId: '',
          }
        }
      };
    },

    computed: {
      loadUrl() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-surcharges?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}`
          + `${this.form.search.queryString}`;
      },

      schema() {
        return [
          { value: 'surchargeId', text: '#', sortable: false },
          { value: 'merchantId', text: this.$t('views.surcharges.merchantId'), sortable: false },
          { value: 'paymentOrderId', text: this.$t('views.surcharges.ddpgOrderId'), sortable: false },
          { value: 'amount', text: this.$t('views.surcharges.amount'), align: 'end', width: 128 },
          { value: 'surchargeStatus', text: this.$t('views.surcharges.status'), sortable: false, align: 'center',},
          { value: 'reason', text: this.$t('views.surcharges.reason'), sortable: false},
          { value: 'createdAt', text: this.$t('views.surcharges.createdAt') },
          { value: 'actions', text: this.$t('views.surcharges.actions'), sortable: false },
        ];
      },
      user() {
        return this.$store.getters.user;
      }
    }
  }
</script>
