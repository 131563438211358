<template>
  <div>
    <v-dialog v-model="show" @click:outside="closeDialog">

      <v-card>
        <v-card-title>
          <v-progress-linear indeterminate color="teal" v-if="loading"></v-progress-linear>

          <strong>Surcharge</strong>
        </v-card-title>

        <v-card-text>
          <v-row>
            <!-- BASIC DATA ITERATOR FOR PAYMENT ORDER INFO -->
            <v-col cols="12" lg="6">
              <H3> Basic Information </H3>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium"> # </td>
                      <td>{{ surchargeId }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.surcharges.merchantId') }}</td>
                      <td>{{ surcharge.merchantId }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.surcharges.ddpgOrderId') }}</td>
                      <td>{{ surcharge.paymentOrderId }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.surcharges.amount') }}</td>
                      <td>{{ surcharge.currency  || 'TWD'}}${{ Number(surcharge.amount).toLocaleString() }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.surcharges.status') }}</td>
                      <td>{{ surcharge.surchargeStatus }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.surcharges.reason') }}</td>
                      <td>{{ surcharge.reason }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" lg="6">
              <H3> Operation History </H3>
              <SSRTable ref="list-history" :data="surcharge.history" :schema="historySchema">
                <div slot="createdAt" slot-scope="{item}">
                  {{ tsToDateString(item.createdAt) }}
                </div>
              </SSRTable>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import SSRTable from '@/components/SSRTable';
import moment from 'moment';

export default {
  name: 'SurchargeDetailDialog',
  components: {
    SSRTable
  },
  props: {
    show: Boolean,
    surchargeId: String
  },
 
  mounted() {

  },

  methods: {
    closeDialog() {
      this.$emit('update:show', false);
    },

    fetchSurchargeInfo() {
      if (!this.surchargeId) {return;}
      this.loading = true;
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ledger-surcharges/${this.surchargeId}`
      )
      .then(response => {
        this.surcharge = response.data;
      })
      .catch(err => {
        console.error(`Failed to fetch surcharge information.`, err.response);
        this.$notify({
          text: `Failed to fetch surcharge information.`,
          type: 'error', 
        });
        this.surcharge = {};
      })
      .finally(() => {
        this.loading = false;
      });
    },
    tsToDateString(ts) {
        if (ts) {
          return moment.unix(ts).format('YYYY/MM/DD HH:mm:ss');
        }
        return '';
      },
  },

  data() {
    return {
      loading: false,

      surcharge: { 
        history: [],
      },
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    historySchema () {
      return [
          { value: 'action', text: this.$t('views.surcharges.actions'), sortable: false },
          { value: 'operatorName', text: this.$t('views.surcharges.operator'), sortable: false },
          { value: 'createdAt', text: this.$t('views.surcharges.operationTime'), sortable: false },
        ];
    }
  },

  watch: {
    'surchargeId': {
      immediate: false,
      handler(id) {
        if (id) {
          this.fetchSurchargeInfo();
        }
      }
    }
  }
}
</script>